<template>
	<div>
		<CustomTableReport
			:total-pages="totalPages"
			:total-items="totalItems"
			:clinics="clinics"
			:is-mobile="isMobile"
			:mobile-role="mobileRole"
			@search="search"
			@export="exportExcel"
		>
			<template #headers>
				<tr>
					<th
						v-for="(header, index1) in headers"
						:key="`${header.title}-${index1}`"
						scope="col"
						:class="`${header.class}`"
						>{{ header.title }}</th
					>
				</tr>
			</template>
			<template #body>
				<template v-if="schedules && schedules.length">
					<tr v-for="(item, index2) in schedules" :key="`${index2}`">
						<template v-if="item && item.length">
							<td scope="row" class="table-no report-schedule__clinic"
								><strong>{{ getNameClinic(item) }}</strong></td
							>
							<td scope="row" class="text-break text-pre-wrap">{{
								getValueMonth(item, MONTH.JAN)
							}}</td>
							<td scope="row" class="text-break text-pre-wrap">{{
								getValueMonth(item, MONTH.FEB)
							}}</td>
							<td scope="row" class="text-break text-pre-wrap">{{
								getValueMonth(item, MONTH.MAR)
							}}</td>
							<td scope="row" class="text-break text-pre-wrap">{{
								getValueMonth(item, MONTH.APR)
							}}</td>
							<td scope="row" class="text-break text-pre-wrap">{{
								getValueMonth(item, MONTH.MAY)
							}}</td>
							<td scope="row" class="text-break text-pre-wrap">{{
								getValueMonth(item, MONTH.JUN)
							}}</td>
							<td scope="row" class="text-break text-pre-wrap">{{
								getValueMonth(item, MONTH.JUL)
							}}</td>
							<td scope="row" class="text-break text-pre-wrap">{{
								getValueMonth(item, MONTH.AUG)
							}}</td>
							<td scope="row" class="text-break text-pre-wrap">{{
								getValueMonth(item, MONTH.SEP)
							}}</td>
							<td scope="row" class="text-break text-pre-wrap">{{
								getValueMonth(item, MONTH.OCT)
							}}</td>
							<td scope="row" class="text-break text-pre-wrap">{{
								getValueMonth(item, MONTH.NOV)
							}}</td>
							<td scope="row" class="text-break text-pre-wrap">{{
								getValueMonth(item, MONTH.DEC)
							}}</td>
							<td scope="row" class="text-break text-pre-wrap">{{ (item && item.sum) || 0 }}</td>
						</template>
					</tr>
				</template>
				<template v-else>
					<tr class="text-center">
						<td colspan="14" class="fit">
							{{ $t("Table.NoData") }}
						</td>
					</tr>
				</template>
			</template>
		</CustomTableReport>
	</div>
</template>

<script>
import {
	GET_CLINIC_LIST,
	GET_REPORT_SCHEDULE_LIST,
	GET_REPORT_SCHEDULE_EXPORT,
} from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState } = createNamespacedHelpers("report")
import { MONTH } from "@/shared/plugins/constants"

export default {
	name: "ReportScheduleManagement",

	props: {
		// Support embedded in mobile
		isMobile: {
			type: [Boolean, String],
			default: false,
		},
		token: {
			type: [String],
			default: null,
		},
		mobileRole: {
			type: [String],
			default: null,
		},
	},

	data() {
		return {
			headers: [
				{
					title: this.$t("ReportScheduleForm.Clinics"),
					class: "fit report-schedule__clinic",
				},
				{
					title: this.$t("ReportScheduleForm.Jan"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Feb"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Mar"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Apr"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.May"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Jun"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Jul"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Aug"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Sep"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Oct"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Nov"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Dec"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Sum"),
					class: "fit text-center",
				},
			],

			schedules: [],
			MONTH: MONTH,
		}
	},

	computed: {
		...mapState(["schedule", "clinics", "totalItems", "totalPages"]),
	},

	created() {
		this.setData()
	},

	methods: {
		...mapActions({
			GET_CLINIC_LIST,
			GET_REPORT_SCHEDULE_LIST,
			GET_REPORT_SCHEDULE_EXPORT,
		}),

		setData: async function () {
			const data = {}
			if (this.isMobile) data.token = this.token
			await this.GET_CLINIC_LIST(data)
		},

		getNameClinic(item) {
			return (item && item[0].clinicName) || ""
		},

		getValueMonth(item, month) {
			let count = item && item.filter(el => el.monthName === month.toUpperCase())
			return (count && count[0] && count[0].countAppointment) || 0
		},

		search: async function (params = {}) {
			this.schedules = []

			const data = { ...params }
			if (this.isMobile) data.token = this.token
			const res = await this.GET_REPORT_SCHEDULE_LIST(data)
			this.rebuildReport(res)
		},

		rebuildReport(data) {
			let _sche = []
			if (data && data.length > 0) {
				data.forEach(el => {
					if (_sche.length === 0) {
						_sche[el.clinicId] = []
						_sche[el.clinicId].push(el)
						_sche[el.clinicId].sum = 0
						_sche[el.clinicId].sum += el.countAppointment
					} else {
						if (_sche[el.clinicId]) {
							_sche[el.clinicId].push(el)
							_sche[el.clinicId].sum += el.countAppointment
						} else {
							_sche[el.clinicId] = []
							_sche[el.clinicId].push(el)
							_sche[el.clinicId].sum = 0
							_sche[el.clinicId].sum += el.countAppointment
						}
					}
				})
			}
			if (_sche && _sche.length) this.schedules = _sche
		},

		exportExcel: async function (params = {}) {
			const data = { ...params }
			if (this.isMobile) data.token = this.token
			this.GET_REPORT_SCHEDULE_EXPORT(data)
		},
	},
}
</script>

<style lang="scss" scoped>
.report-schedule {
	&__clinic {
		min-width: 120px;
		max-width: 200px;
		white-space: nowrap !important;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

/deep/ .multiselect__option {
	white-space: normal;
}
</style>
